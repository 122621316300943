<template>
  <div class="dashboard-kpi layer-4">
    <div :class="headerClassName">
      {{ kpi.title }}
    </div>
    <div class="dashboard-kpi__container">
      <component :is="kpiComponent" :kpi="kpi"></component>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import DashboardKpiDefault from './DashboardKpiDefault.vue'
import DashboardKpiProgramExport from './DashboardKpiProgramExport.vue'
import DashboardKpiProgramDomesticMarket from './DashboardKpiProgramDomesticMarket.vue'
import DashboardKpiProgramPD from './DashboardKpiProgramPD.vue'
import DashboardKpiUtilization from './DashboardKpiUtilization.vue'
import DashboardKpiAccumulated from './DashboardKpiAccumulated.vue'
import DashboardKpiWeeklyPlan from './DashboardKpiWeeklyPlan.vue'
import DashboardKpiStatus from './DashboardKpiStatus.vue'
import DashboardKpiGauge from './DashboardKpiGauge.vue'
import DashboardKpiGaugeDetails from './DashboardKpiGaugeDetails.vue'
import DashboardKpiStockAvailability from './DashboardKpiStockAvailability.vue'
import DashboardKpiSalesAttendance from './DashboardKpiSalesAttendance.vue'

export default defineComponent({
  name: 'DashboardKpi',
  props: {
    kpi: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    kpiComponent() {
      switch (this.kpi.type) {
        case 'utilization':
          return DashboardKpiUtilization
        case 'accumulated':
          return DashboardKpiAccumulated
        case 'weeklyPlanStock':
          return DashboardKpiStockAvailability
        case 'weeklyPlan':
          return DashboardKpiWeeklyPlan
        case 'status':
          return DashboardKpiStatus
        case 'gauge':
          return DashboardKpiGauge
        case 'gaugeDetails':
          return DashboardKpiGaugeDetails
        case 'programExport':
          return DashboardKpiProgramExport
        case 'programDomesticMarket':
          return DashboardKpiProgramDomesticMarket
        case 'programPD':
          return DashboardKpiProgramPD
        case 'weeklySalesAttendance':
          return DashboardKpiSalesAttendance
        default:
          return DashboardKpiDefault
      }
    },
    headerClassName() {
      return {
        'dashboard-kpi__header': true,
        'dashboard-kpi__header--small': this.small
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-kpi {
  padding: 10px;
  height: 100%;

  &__header {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #959db5;

    &--small {
      font-size: 12px;
      font-weight: normal;
    }
  }

  &__container {
    height: 100%;
  }
}
</style>
