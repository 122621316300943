import { render, staticRenderFns } from "./DashboardKpi.vue?vue&type=template&id=041aa5ac&scoped=true&"
import script from "./DashboardKpi.vue?vue&type=script&lang=js&"
export * from "./DashboardKpi.vue?vue&type=script&lang=js&"
import style0 from "./DashboardKpi.vue?vue&type=style&index=0&id=041aa5ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041aa5ac",
  null
  
)

export default component.exports